<template>
  <div class="record section">
    <div class="record__inner container">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="form-group col-md-2">
            <label>Sonlar xonasi:</label>
            <select v-model="form.number_length" class="form-control">
              <option
                v-for="(number, index) in generateNumber(1, 7, 1)"
                :key="index"
                :value="number"
              >
                {{ number }}
              </option>
            </select>
          </div>
          <div class="form-group col-md-2">
            <label>Jarayon vaqti:</label>
            <input
              v-model="form.number_speed"
              class="form-control"
              max="3600"
              min="1"
              step="1"
              type="number"
            />
          </div>
          <div class="form-group col-md-2">
            <label>Sonlar qatori:</label>
            <input
              v-model="form.number_row"
              type="number"
              class="form-control"
              min="1"
              max="300"
            />
          </div>
          <div class="form-group col-md-2">
            <label>Misollar soni:</label>
            <input
              v-model="form.number_count"
              type="number"
              class="form-control"
              min="1"
              max="300"
            />
          </div>
          <div class="form-group col-md-2 align-self-end">
            <button type="submit" class="btn btn-orange">Qidirish</button>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table text-white">
          <thead>
            <tr>
              <th colspan="5">
                <h1 class="record__title">
                  {{ tableName }}
                </h1>
              </th>
            </tr>
            <tr>
              <th scope="col">№</th>
              <th scope="col">Ismi</th>
              <th scope="col">Javoblar soni</th>
              <th scope="col">Sarflangan vaqti</th>
              <th scope="col">Vaqti</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, index) in records.data" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ record.username }}</td>
              <td>{{ record.answer }} ta</td>
              <td>{{ record.time - 1 }} s</td>
              <td>{{ record.created_at | datetime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "settings",
  data: function () {
    return {
      tableName: "Ildiz",
      form: {
        number_length: 1,
        number_row: 3,
        number_count: 3,
        number_speed: 300,
      },
    };
  },
  methods: {
    submit: async function () {
      await this.$store.dispatch("root/setListRecord", this.form);
    },
    generateNumber: function (begin, end, step = 1) {
      let numbers = [];
      for (let i = begin; i <= end; i += step) {
        numbers.push(i);
      }
      return numbers;
    },
    toDate(timestamp) {
      let time = new Date(timestamp * 1000);
      return time.toLocaleTimeString() + " " + time.toDateString();
    },
  },
  computed: {
    records() {
      return this.$store.getters["root/state"].record.list;
    },
  },
  mounted() {
    if (this.records.settings !== null) {
      this.form.number_length = this.records.settings.number_length;
      this.form.number_speed = this.records.settings.number_speed;
    }
  },
};
</script>
